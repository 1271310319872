.node {
	fill: var(--WHITE);
	stroke: var(--BLACK);
	stroke-width: 1;
	transition: all 0.25s;

	&.inactive {
		opacity: 0.1;
	}

	&.active {
		stroke: var(--RED);
	}

	&.hovered,
	&.selected {
		&:not(.inactive) {
			rect {
				stroke-width: 3;
				stroke: var(--BLUE);
			}
			image {
				cursor: pointer;
			}
		}
	}
}

.link {
	stroke-width: 1;
	stroke: var(--BLUE);
	fill: none;
	stroke-opacity: 0.25;
	transition: all 0.25s;

	&.inactive {
		stroke: var(--GREY);
		stroke-opacity: 0;
	}

	&.active {
		stroke: var(--RED);
		stroke-opacity: 0.8;
		stroke-width: 2;
	}
}
