button {
    background-color: var(--RED);
    border-width: 1px;
    padding: 10px;
    border-style: solid;
    border-color: var(--BLACK);
    & a {
        color: var(--WHITE);
    }
}

.container {
    position: absolute;
    z-index: 1;
    background-color: var(--WHITE);
    transition: all 0.25s;
    width: 100vw;
    height: 350px;
    overflow: hidden;

    .toggle {
        right: 5px;
    }

    &.expanded {
        bottom: 0;

        .toggle {
            transform: rotateZ(90deg);
        }
    }

    &.collapsed {
        bottom: -300px;

        .toggle {
            transform: rotateZ(-90deg);
        }

        .episode {
            opacity: 0;
        }
    }

    @media screen and (min-width: 1025px) {
        width: 400px;
        height: calc(100vh - var(--HEADER_HEIGHT));
        top: var(--HEADER_HEIGHT);

        .thumbnail {
            max-width: 50%;
        }

        .toggle {
            top: 15px;
            right: 0px;
        }

        &.expanded {
            left: 0;

            .toggle {
                transform: rotateY(180deg);
            }
        }

        &.collapsed {
            left: -350px;
            bottom: 0;

            .toggle {
                transform: rotateY(0deg);
            }
        }
    }
}

.toggle {
    position: absolute;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: all 0.25s;
    color: var(--BLACK);
    z-index: 2;
    &:hover {
        color: var(--BLUE);
    }
}

.episode {
    overflow-y: auto;
    height: 100%;
    padding: 20px 50px;
    box-sizing: border-box;
    transition: opacity 0.25s;
}

.title {
    margin-bottom: 10px;
    text-transform: uppercase;
}

.contentWrapper {
    overflow: hidden;
    text-align: left;
    display: flex;
    flex-direction: column;
}

.description {
    margin-bottom: 10px;
}

.thumbnail {
    max-width: 30%;
    aspect-ratio: 1;
    margin: 10px;
    align-self: center;
    transition: filter 0.3s ease-out;
}

.blur {
    filter: blur(10px);
}

a {
    .highlight {
        color: var(--RED);
    }
}

.highlight {
    background-color: var(--HIGHLIGHT);
    vertical-align: middle;
    display: inline;
    line-height: 1;
    padding: 0.2em 0 0 0;
}

.subscribers {
    font-style: italic;
    margin-bottom: 10px;
}
