.container {
	margin-bottom: 10px;
}

.tag {
	background-color: var(--RED);
	padding: 5px;
	border-radius: 10px;
	color: var(--WHITE);
	border: 1px solid var(--GREY);
	display: inline-block;
	vertical-align: middle;
	font-size: 0.75em;
}

.tagButton {
	&:hover {
		cursor: pointer;
		.tag {
			border: 1px solid var(--WHITE);
		}
	}
	background-color: transparent;
	padding: 0;
	margin: 0;
	border: none;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin-right: 5px;
	margin-bottom: 5px;
}
.highlight {
	background-color: var(--HIGHLIGHT);
	color: var(--BLACK);
}
