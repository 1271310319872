.container {
    background-color: var(--WHITE);
    padding: 8px;
    border: 1px solid var(--GREY);
    width: var(--INPUT_WIDTH);
    @media screen and (min-width: 1025px) {
        margin-right: 20px;
    }
}

.instruction {
    font-size: 12px;
    margin-bottom: 5px;
    text-align: left;
}

.inputWrapper {
    position: relative;
    background-color: white;
    border: 1px solid var(--GREY);
    width: 100%;
    height: var(--INPUT_HEIGHT);
    display: flex;
    align-items: center;
    margin: auto;
    pointer-events: auto;
    &:has(input:focus) {
        outline: 2px solid var(--BLUE);
    }
}

.filter {
    background-color: transparent;
    border: none;
    flex: 1;
    width: 0;
    font-size: 20px;
    padding: 0px 5px;
    &:focus {
        outline: none;
    }
    &::placeholder {
        left: 5px;
        font-size: 18px;
        color: var(--GREY);
    }
}

.placeholder {
    position: absolute;
    font-size: 18px;
    color: var(--GREY);
    left: 5px;
    user-select: none;
    pointer-events: none;
    transition: opacity 1s ease;

    &.animate {
        opacity: 0;
    }
}

.closeButton {
    background-color: transparent;
    border: none;
    padding-right: 5px;
    margin: 0;
    color: var(--BLACK);

    &:hover {
        cursor: pointer;
    }
}

.dropdown {
    position: absolute;
    top: calc(var(--INPUT_HEIGHT) + 1px);
    width: 300px;
    text-align: left;
    background-color: var(--WHITE);
    max-height: 50vh;
    overflow: scroll;
}
