.container {
    text-align: center;
    height: 100vh;
    width: 100vw;
    overflow: auto;
    background-color: #2c2926;
    display: flex;
    flex-direction: row;
}

.header {
    width: 100%;
    height: var(--HEADER_HEIGHT);
    background-color: var(--WHITE);
    z-index: 2;
    font-size: 18px;
    position: relative;
    border-bottom: 1px solid var(--GREY);
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (min-width: 1025px) {
        justify-content: flex-start;
        padding-left: 10px;
    }
}

.divider {
    margin-left: 10px;
    margin-right: 10px;
}

.episodes {
    text-decoration: underline;
}

.graph {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.controls {
    width: 100%;
    position: absolute;
    padding-top: var(--HEADER_HEIGHT);
    top: 20px;
    pointer-events: none;
    justify-content: center;
    align-items: center;
    display: flex;
    @media screen and (min-width: 1025px) {
        justify-content: flex-end;
    }
}

.loading {
    color: var(--WHITE);
    display: flex;
    flex-direction: column;
    font-size: 32px;
}

@keyframes rotateScale {
    0% {
        transform: rotateZ(0deg) scale(1);
    }
    50% {
        transform: rotateZ(180deg) scale(0.75);
    }
    100% {
        transform: rotateZ(360deg) scale(1);
    }
}

.spinner {
    width: 25%;
    align-self: center;
    margin-bottom: 10px;
    animation: rotateScale 2s infinite linear;
    border: 3px solid var(--RED);
    border-radius: 50%;
}
