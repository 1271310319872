:root {
	--GREY: #999998;
	--BLUE: #abbbd4;
	--RED: #e10600;
	--BLACK: #2d2926;
	--WHITE: #f5f5f1;
	--HIGHLIGHT: #f7f181;
	--INPUT_HEIGHT: 40px;
	--INPUT_WIDTH: 300px;
	--HEADER_HEIGHT: 40px;
	--CLOSED_SIDEBAR_SIZE: 50px;
}

body {
	background-color: var(--BLACK);
	color: var(--BLACK);
	overflow: hidden;
}

html {
	overflow: hidden;
}

@font-face {
	font-family: 'Univers';
	src: url('./assets/UniversLTPro-55Roman.otf');
}

/* src/assets/styles/fonts.css */
@font-face {
	font-family: 'HappyTimes';
	src:
		url('./assets/happy-times-NG_regular_master_web.woff2') format('woff2'),
		url('./assets/happy-times-NG_regular_master_web.woff') format('woff'),
		url('./assets/happy-times-NG_regular_master_web.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'HappyTimes';
	src:
		url('./assets/happy-times-NG_bold_master_web.woff2') format('woff2'),
		url('./assets/happy-times-NG_bold_master_web.woff') format('woff'),
		url('./assets/happy-times-NG_bold_master_web.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'HappyTimes';
	src:
		url('./assets/happy-times-NG_italic_master_web.woff2') format('woff2'),
		url('./assets/happy-times-NG_italic_master_web.woff') format('woff'),
		url('./assets/happy-times-NG_italic_master_web.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

body,
input {
	font-family: 'Univers', sans-serif;
	font-weight: bold;
}

a {
	font-weight: bold;
	color: var(--RED);
	&:visited {
		color: var(--BLUE);
	}
	&:hover {
		cursor: pointer;
	}
}

h1 {
	font-size: 1.5em;
	margin: 0;
}

h3 {
	font-size: 1.1em;
	margin: 0;
}

h1,
h3 {
	text-wrap: balance;
}

p {
	margin-top: 0;
}
