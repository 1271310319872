.container {
	color: var(--WHITE);
	overflow-y: scroll;
	height: 100vh;
	padding-left: 10px;
}

.title {
	margin-bottom: 20px;
}

.month {
	text-decoration: underline;
}

.result {
	margin-bottom: 20px;
}

.details {
	font-weight: normal;
	padding-top: 5px;
	margin-left: 10px;
}
